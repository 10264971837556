(function ($) {
  var _load_pixlee = function () {
    var s = document.createElement('script');
    s.src = 'https://assets.pxlecdn.com/builds/20230830_173900_pixlee_widget_1_0_0.js';
    s.integrity = 'sha384-bdI4cuu0fVSVc8FtNp6ZcURHWU+DCEXBKbvw6q5BbtbY36wBeJA0xft9UOoKutTs';
    s.crossOrigin = 'anonymous';
    s.type = 'text/javascript';
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  };

  var isCookieConsentNeeded = function () {
    let consentCookieValue = $.cookie('OptanonConsent');

    // If cookie is not set at all, return true.
    if (!consentCookieValue) {
      return true;
    }

    // Decode cookie value from querystring format to array.
    let decodedCookieValueArr = decodeURIComponent(consentCookieValue.replace(/\+/g, ' ')).split('&');

    // Search for 'groups' component in the cookie value.
    let groups = decodedCookieValueArr.filter(function (value) {
      return (value.indexOf('groups') >= 0);
    });

    if (groups.length) {
      // Group '3:0' => cookie consent denied.
      // Group '3:1' => cookie consent given.
      let group = groups.toString().split('=')[1];
      if (typeof group !== 'undefined' && group.indexOf('3:1') !== -1) {
        return false;
      }
    }

    // By default, we assume consent is needed.
    return true;
  }

  var _pixlee_widgets = function ($widgets, apiKey) {
    const func = () => {
      Pixlee.init({ apiKey });

      $widgets.each(function () {
        const $this = $(this);
        const containerId = $this.attr('id');
        const type = $this.data('pixlee-widget-type');
        const widgetId = $this.data('pixlee-widget-id');
        const accountId = $this.data('pixlee-account-id');
        const skuId = $this.data('pixlee-sku-id');
        const parent_category = skuId;
        const categoryName = $this.data('pixlee-category-name');
        const fallbackAlbumId = $this.data('pixlee-fallback-album-id');
        const fallbackThreshold = 5;
        const getCookieConsent = isCookieConsentNeeded();
        var method = 'addProductWidget';

        let payload = { widgetId, containerId, getCookieConsent };
        if (type === 'simple') {
          method = 'addSimpleWidget';
        }
        if (type === 'product')  {
          method = 'addProductWidget';
          payload = {
            ...payload,
            accountId,
            skuId,
            parent_category
          };
        }
        if (type === 'category')  {
          method = 'addCategoryWidget';
          payload = {
            ...payload,
            accountId,
            categoryName,
            fallbackAlbumId,
            fallbackThreshold
          };
        }

        Pixlee[method](payload);
        $this.parent('.elc-pixlee-v1').addClass('initialized');
      });

      // Added subscribed events for analytics.
      Pixlee.addSimpleWidget({
        widgetId: 12345,
        subscribedEvents: ['widgetLoaded', 'photoOpened', 'photoClosed', 'photoChanged', 'ctaClicked', 'ctaChanged', 'widgetNumPhotos',
          'widgetLoadMore', 'widgetNavigated', 'uploaderOpened', 'uploadComplete', 'atcComplete', 'socialShare', 'widgetHidden'],
      });
    };

    return func;
  };

  function createObserver(observerElement) {
    let observer;

    let options = {
      root: null,
      rootMargin: "500px",
      threshold: 1.0,
    };

    observer = new IntersectionObserver(observerCallback, options);
    observer.observe(observerElement);
  }

  let firstTimeRender = true;

  const observerCallback = (entries, observer) => {
    if (entries[0].isIntersecting && firstTimeRender) {
      _load_pixlee();
      firstTimeRender = false;
      observer.disconnect();
    }
  };

  var _setup_widgets = function ($widgets, apiKey) {
    window.PixleeAsyncInit = _pixlee_widgets($widgets, apiKey);
  };

  Drupal.behaviors.elcPixleeV1 = {
    attached: false,

    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      const widgetContainers = document.getElementsByClassName('elc-ugc-v1');
      const $widgets = $('[data-ugc-component="pixlee"]');
      const enablePixlee = $widgets.length > 0 && typeof settings.elc_ugc_media !== 'undefined';

      if (enablePixlee) {
        _setup_widgets($widgets, settings.elc_ugc_media.pixlee.apiKey);
        createObserver(widgetContainers[0]);
      }
    }
  };
})(jQuery);
